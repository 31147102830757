import React from 'react';

const About = () => {
  return (
    <div
      style=
        {{ position: "absolute", marginTop: "70px", fontSize: "20px" }}
    >
      <h1>We are group of Engineers based out of hyderabad India</h1>
    </div>
  );
};

export default About;